/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: footer.tsx
 * Description: This file is the blog page component.
 */

import React from 'react';
import Layout from '../component/layout';

//import images
import securitycover from '../assets/images/security-cover.png';
import gdprlogo from '../assets/images/gdpr.png';
import isologo from '../assets/images/iso-9001.png';
import isologo1 from '../assets/images/iso.png';
import role from '../assets/images/role-based.png';
import log from '../assets/images/log-manage.png';
import two from '../assets/images/two-factor.png';
import data from '../assets/images/data-masking.png';
import { Link } from 'react-router-dom';

const SecurityPage: React.FC = () => {
    // Add your component logic here

    return (
        <Layout>
            <div className="bg-white flex flex-col">

                <div className="items-stretch flex gap-0 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-2 pr-2">
                        <div className="text-orange-400 text-sm font-medium leading-5">
                            Home
                        </div>
                        <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    </div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Security
                    </div>
                </div>


                <div className="flex-col overflow-hidden self-stretch relative flex min-h-[673px] w-full items-center mt-7 px-20 py-12 max-md:max-w-full max-md:px-5">
                    <img
                        loading="lazy"
                        src={securitycover}
                        srcSet={`${securitycover} 1x, ${securitycover} 2x , ${securitycover} 4x, ${securitycover} 8x, ${securitycover} 12x, ${securitycover} 16x, ${securitycover} 20x`}
                        alt='securitycover'
                        className="absolute h-full w-full object-cover object-center inset-0"
                    />
                </div>


                <div className="bg-gray-100 flex w-full flex-col items-stretch mt-16 pt-2.5 max-md:max-w-full max-md:mt-10">
                    <div className='self-center w-full max-w-[1254px] mt-20 mb-14 max-md:max-w-full max-md:my-10'>
                        <div className="text-blue-950 text-center text-5xl font-bold leading-10 max-md:max-w-full">
                            Secure & Compliant
                        </div>
                        <div className="text-zinc-600 text-center text-xl font-medium leading-7 max-md:max-w-full mt-5">
                            Safeguarding Your Data, Empowering Your Business.
                        </div>

                        <div className="self-stretch w-full mt-10 px-5 max-md:max-w-full max-md:mt-10">
                            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                <div className="flex flex-col items-stretch w-[100%] max-md:w-full max-md:ml-0 h-full">
                                    <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                                        <div className="text-blue-950 text-left text-xl font-semibold leading-7 ml-5 whitespace-nowrap mt-8">
                                            Uncompromising Security: Your Data's Fortress
                                        </div>
                                        <div className="text-zinc-600 text-left text-base leading-7">
                                            <ul className='mt-5'>
                                                <li className='list-disc ml-10'>Sensitive data is encrypted at rest using AWS Key Management Service (KMS), ensuring confidentiality and integrity.</li>
                                                <li className='list-disc ml-10'>We continuously monitor our infrastructure and configuration using Datadog, promptly detecting and addressing any security vulnerabilities or anomalies.</li>
                                                <li className='list-disc ml-10'>Access credentials and API keys are securely stored and managed within a hardened Vault Cluster, adhering to best practices for secret management.</li>
                                                <li className='list-disc ml-10'>Your data is stored in AWS S3 with automatic replication across multiple regions, ensuring high availability, durability, & compliance with data residency requirements.</li>
                                                <li className='list-disc ml-10'>We maintain a robust, real-time disaster recovery setup to minimize downtime & ensure service availability with a 99.99% uptime SLA.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative self-center w-full max-w-[1254px] mt-6 mb-20 max-md:max-w-full max-md:my-10">
                             <div className="text-blue-950 text-left text-xl font-semibold leading-7 ml-10 whitespace-nowrap mt-8">
                                        Built On Trust: Certificates That Matter
                                    </div>
                            <div className="self-stretch w-full mt-10 px-5 max-md:max-w-full max-md:mt-10">
                                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                   
                                    <div className="bg-white p-6 flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0 rounded-3xl">
                                        <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                                            {/* IMAGE 1 */}
                                            <img src={isologo} alt="ISO" className="aspect-[0.95] object-contain object-center w-[200px] overflow-hidden self-center max-w-full" />
                                    </div>
                                    </div>
                                    <div className="bg-white p-6 flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0 rounded-3xl">
                                        <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                                            {/* IMAGE 2 */}
                                            <img src={gdprlogo} alt="GDPR" className="aspect-[0.95] object-contain object-center w-[200px] overflow-hidden self-center max-w-full" />
                                    </div>
                                    </div>
                                    <div className="bg-white p-6 flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0 rounded-3xl">
                                        <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                                            {/* IMAGE 3 */}
                                            <img src={isologo1} alt="ISO" className="aspect-[0.95] object-contain object-center w-[200px] overflow-hidden self-center max-w-full" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative self-center w-full max-w-[1254px] mt-6 mb-20 max-md:max-w-full max-md:my-10">
                            <div className="text-blue-950 text-left text-xl font-semibold leading-7 ml-10 whitespace-nowrap mt-8">
                                Power & Simplicity
                            </div>
                            <div className="self-stretch w-full mt-10 px-5 max-md:max-w-full max-md:mt-10">
                                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">


                                    <div className="bg-white p-6 flex flex-col items-stretch w-[25%] max-md:w-full max-md:ml-0 rounded-3xl">
                                        <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                                            {/* <svg className='aspect-[0.95] object-contain object-center w-[84px] overflow-hidden self-center max-w-full' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 44" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="9" cy="7" r="4"></circle>
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg> */}
                                            <img src={role} alt="role-based" className="aspect-[0.95] object-contain object-center w-[200px] overflow-hidden self-center max-w-full" />
                                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                                Role-Based Access
                                            </div>
                                            <div className="text-zinc-600 text-center text-base leading-7">
                                                Control who sees what with fine-grained permissions.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white p-6 flex flex-col items-stretch w-[25%] max-md:w-full max-md:ml-0 rounded-3xl">
                                        <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                                            {/* <svg className='aspect-[0.95] object-contain object-center w-[84px] overflow-hidden self-center max-w-full' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 44" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                <polyline points="14 2 14 8 20 8"></polyline>
                                                <line x1="12" y1="18" x2="12" y2="12"></line>
                                                <line x1="9" y1="15" x2="15" y2="15"></line>
                                            </svg> */}
                                            <img src={log} alt="log" className="aspect-[0.95] object-contain object-center w-[200px] overflow-hidden self-center max-w-full" />
                                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                                Log Management
                                            </div>
                                            <div className="text-zinc-600 text-center text-base leading-7">
                                                Track all activity for transparency and auditing.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white p-6 flex flex-col items-stretch w-[25%] max-md:w-full max-md:ml-0 rounded-3xl">
                                        <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                                            {/* <svg className='aspect-[0.95] object-contain object-center w-[84px] overflow-hidden self-center max-w-full' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 44" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                            </svg> */}
                                            <img src={two} alt="two" className="aspect-[0.95] object-contain object-center w-[200px] overflow-hidden self-center max-w-full" />
                                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                                Two-Factor Authentication
                                            </div>
                                            <div className="text-zinc-600 text-center text-base leading-7">
                                                Add an extra layer of security to your logins.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white p-6 flex flex-col items-stretch w-[25%] max-md:w-full max-md:ml-0 rounded-3xl">
                                        <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                                            {/* <svg className='aspect-[0.95] object-contain object-center w-[84px] overflow-hidden self-center max-w-full' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 44" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                                                <polyline points="14 2 14 8 20 8"></polyline>
                                                <line x1="12" y1="18" x2="12" y2="12"></line>
                                                <line x1="9" y1="15" x2="15" y2="15"></line>
                                                <path d="M22 2l-2 5"></path>
                                                <path d="M10 12l2-5"></path>
                                            </svg> */}
                                            <img src={data} alt="data" className="aspect-[0.95] object-contain object-center w-[200px] overflow-hidden self-center max-w-full" />
                                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                                Data Masking
                                            </div>
                                            <div className="text-zinc-600 text-center text-base leading-7">
                                                Protect sensitive details selectively within documents.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-center items-stretch self-center flex gap-5 mt-24 mb-24 px-5 max-md:mt-10">
                    <div className="text-orange-400 text-center text-2xl font-medium leading-5 underline grow whitespace-nowrap">

                    </div>
                </div>

            </div>
        </Layout >
    );
};

export default SecurityPage;
